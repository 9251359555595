import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CssBaseline, Box, Toolbar } from '@mui/material';
import DashboardPage from './DashboardPage';
import Sender from './Sender';
import MainList from './MainList';
import Content from './Content';
import Links from './Links';
import Authentication from './Authentication';
import TotalEvaluation from './TotalEvaluation';
import EmailSelectionPage from './EmailSelectionPage';

const App = () => {
  const [listItems, setListItems] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
 // const [selectedFields, setSelectedFields] = useState({});
//  const [id, setid] = useState(null);
//const [timestamp, settimestamp] = useState(null);
//const [domain, setdomain] = useState(null);
//const [companyID, setcompanyID] = useState(null);
const [emailAddress, setemailAddress] = useState(null);
const [displayName, setdisplayName] = useState(null);
//const [fromEmailAddress, setfromEmailAddress] = useState(null);
//const [fromDisplayName, setfromDisplayName] = useState(null);
//const [fromRecipientType, setfromRecipientType] = useState(null);
//const [senderEmailEmailAddress, setsenderEmailEmailAddress] = useState(null);
//const [senderEmailDisplayName, setsenderEmailDisplayName] = useState(null);
//const [senderEmailRecipientType, setsenderEmailRecipientType] = useState(null);
//onst [senderDomain, setsenderDomain] = useState(null);
//const [classification, setclassification] = useState(null);
//const [certainty, setcertainty] = useState(null);
//const [details, setdetails] = useState(null);
//const [emailBody, setemailBody] = useState(null);
//const [mailHeaders, setmailHeaders] = useState(null);
const [spf_score, setspf_score] = useState(null);
const [dkim_score, setdkim_score] = useState(null);
const [dmarc_score, setdmarc_score] = useState(null);
const [total_score, settotal_score] = useState(null);
const [spf_text, setspf_text] = useState(null);
const [dkim_text, setdkim_text] = useState(null);
const [dmarc_text, setdmarc_text] = useState(null);
const [senderScore, setsenderScore] = useState(null);
const [nameSimilarity, setnameSimilarity] = useState(null);
const [ipAddress, setipAddress] = useState(null);
const [ipURL, setipURL] = useState(null);
//const [sendTotVal, setsendTotVal] = useState(null);
const [linkScore, setlinkScore] = useState(null);
//const [linkNameSimilarity, setlinkNameSimilarity] = useState(null);
const [linksTotVal, setlinksTotVal] = useState(null);
const [numLinks, setnumLinks] = useState(null);
const [numRedirect, setnumRedirect] = useState(null);
const [numEncoding, setnumEncoding] = useState(null);
const [linksHTTPS, setlinksHTTPS] = useState(null);
const [linkArray, setlinkArray] = useState(null);
const [linkRedirectScore, setlinkRedirectScore] = useState(null);
const [linkHttpsScore, setlinkHttpsScore] = useState(null);
const [linkEncodeScore, setlinkEncodeScore] = useState(null);
const [linkDuplicateScore, setlinkDuplicateScore] = useState(null);
const [duplicateLinks, setduplicateLinks] = useState(null);
const [receiverScore, setreceiverScore] = useState(null);
const [dictScore, setdictScore] = useState(null);
const [contTotVal, setcontTotVal] = useState(null);
const [count, setcount] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmailList();
  }, []);

  const fetchEmailList = async () => {
    try {
      const response = await fetch('/email_list');
      const data = await response.json();
      setListItems(data);
            } catch (error) {
      console.log('Error fetching email list:', error);
    }
  };

  const fetchEmailDetails = async (id) => {
    try {
      const response = await fetch('/emails_select', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });
  
      if (!response.ok) {
        throw new Error();
      }
  
      const data = await response.json();
      console.log(data);
      console.log(data.id);
      
      setSelectedEmail(data.id); // Assuming selectedEmail is just the ID
      setemailAddress(data.emailAddress);
      setdisplayName(data.displayName);
      setspf_score(data.spf_score);
      setdkim_score(data.dkim_score);
      setdmarc_score(data.dmarc_score);
      settotal_score(data.total_score);
      setspf_text(data.spf_text);
      setdkim_text(data.dkim_text);
      setdmarc_text(data.dmarc_text);
      setsenderScore(data.senderScore);
      setnameSimilarity(data.nameSimilarity);
      setipAddress(data.ipAddress);
      setipURL(data.ipURL);
      setlinkScore(data.linkScore);
      setlinksTotVal(data.linksTotVal);
      setnumLinks(data.numLinks);
      setnumRedirect(data.numRedirect);
      setnumEncoding(data.numEncoding);
      setlinksHTTPS(data.linksHTTPS);
      setlinkArray(data.linkArray);
      setlinkRedirectScore(data.linkRedirectScore);
      setlinkHttpsScore(data.linkHttpsScore);
      setlinkEncodeScore(data.linkEncodeScore);
      setlinkDuplicateScore(data.linkDuplicateScore);
      setduplicateLinks(data.duplicateLinks);
      setreceiverScore(data.receiverScore);
      setdictScore(data.dictScore);
      setcontTotVal(data.contTotVal);
      setcount(data.count);
      
      
      navigate('/MainList');
    } catch (error) {
      console.error('Error fetching data:', error);
      // Optionally set error state here to inform the user
    }
  };

  return (
   // <div>
   <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DashboardPage>
      <Toolbar />
      <Routes>
          <Route
            path="/"
            element={
              <EmailSelectionPage
                listItems={listItems}
                onEmailSelect={fetchEmailDetails}
              />
            }
          />
          <Route
            path="/MainList"
            element={
              selectedEmail ? (
                <MainList
                  selectedEmail={selectedEmail}
                  sendScore={senderScore}
                  messageScore={contTotVal}
                  linkScore={linksTotVal}
                  authScore={total_score}
                />
              ) : (
                <div>Please select an email first.</div>
              )
            }
          />
          <Route path="/TotalEvaluation" element={<TotalEvaluation />} />
          <Route
            path="/Sender"
            element={
              <Sender
                  displayName={displayName}
                  emailAdr={emailAddress}
                  ipAdr={ipAddress}
                  ipURL={ipURL}
                  senderScore={senderScore}

                  nameSimilarity={nameSimilarity}
              />
            }
          />
          <Route
            path="/Content"
            element={
              <Content
                receiverScore={receiverScore}
                dictScore={dictScore}
                spamWordCount={count}
                totVal={contTotVal}
                nameCount={count}
                displayName={displayName}
              />
            }
          />
          <Route
            path="/Links"
            element={
              <Links
              emailAdr={emailAddress}
              displayName={displayName}
              numLinks={numLinks}
              numRedirect={numRedirect}
              numEncoding={numEncoding}
              linksHTTPS={linksHTTPS}
              linkArray={linkArray}
              linkRedirectScore={linkRedirectScore}
              linkHttpsScore={linkHttpsScore}
              linkEncodeScore={linkEncodeScore}
              linkDuplicateScore={linkDuplicateScore}
              linkScore={linkScore}
              duplicateLinks={duplicateLinks}
              linksTotVal={linksTotVal}
          
              />
            }
          />
          <Route
            path="/Authentication"
            element={
              <Authentication
              dmarcScore={dmarc_score}
              dkimScore={dkim_score}
              spfScore={spf_score}
              totVal={total_score}
              spfText={spf_text}
              dkimText={dkim_text}
              dmarcText={dmarc_text}
              />
            }
          />
          </Routes>
          </DashboardPage>
          </Box>
  );
};

export default App;
