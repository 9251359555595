import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import MouseOverPopover from './MouseOverPopover';

// Sender component updated to use props and MUI v5
const Sender = ({
  emailAdr,
  displayName,
  ipAdr,
  ipURL,
  senderScore,
  nameSimilarity,
  sendTotVal,
}) => {
  // Giving color to the percent bar based on the score
  const getProgressBarStyle = (score) => {
    if (score <= 25) return { backgroundColor: 'red' };
    if (score > 25 && score <= 50) return { backgroundColor: 'OrangeRed' };
    if (score > 50 && score <= 75) return { backgroundColor: 'orange' };
    return { backgroundColor: 'green' };
  };

  return (
    <div style={{ paddingLeft: '1em' }}>
      <div style={{ maxHeight: '40em', overflowY: 'auto', paddingLeft: '.1em' }}>
        <Typography variant="h6" component="h3">
          Sender Information
        </Typography>
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
            wordBreak: 'break-all',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="h3">
              Sender Persona
            </Typography>
            <MouseOverPopover
              tekst="Compares the sender's display name with their email address and gives a score based on similarities in words/domain/name."
            />
          </div>
          <div style={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white' }}>
            <div style={{ ...getProgressBarStyle(senderScore), width: '100%', height: '30px' }}>
              <p><b>{senderScore}%</b></p>
            </div>
          </div>
          <p>
            Display Name:<br /> <b>{displayName}</b>
          </p>
          <p>
            Email Address:<br /> <b>{emailAdr}</b>
          </p>
          <p>Similarity:<br /> <b>{nameSimilarity}</b></p>
        </Paper>
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
            wordBreak: 'break-all',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="h3">
              Sender's IP
            </Typography>
            <MouseOverPopover
              tekst="Click the button to check if the IP-address of the email sender is blacklisted."
            />
          </div>
          <p>Sender's IP: <b>{ipAdr}</b></p>
          <Button variant="contained" color="primary" href={ipURL} target="_blank">
            Check IP
          </Button>
        </Paper>
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
            wordBreak: 'break-all',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="h3">
              Evaluation
            </Typography>
            <MouseOverPopover
              tekst="A total evaluation of the sender information found in the email. The percent is an average of all the attributes."
            />
          </div>
          <div style={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white' }}>
            <div style={{ ...getProgressBarStyle(sendTotVal), width: '100%', height: '30px' }}>
              <p><b>{sendTotVal}%</b></p>
            </div>
          </div>
          <p>Total evaluation in percent</p>
        </Paper>
      </div>
      <Button variant="contained" color="primary" component={Link} to="/" sx={{ marginTop: 1 }}>
        Go back
      </Button>
    </div>
  );
};

Sender.propTypes = {
  emailAdr: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  ipAdr: PropTypes.string.isRequired,
  ipURL: PropTypes.string.isRequired,
  senderScore: PropTypes.number.isRequired,
  nameSimilarity: PropTypes.string.isRequired,
  sendTotVal: PropTypes.number.isRequired,
};

export default Sender;
