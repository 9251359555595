import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';



// Helper function to determine the color based on the score
const getBarColor = (score) => {
  if (score <= 25) return 'red';
  if (score <= 50) return 'orangeRed';
  if (score <= 75) return 'orange';
  return 'green';
};

const Content = ({
  receiverScore,
  dictScore,
  spamWordCount,
  totVal,
  nameCount,
  spamWordsList,
  displayName,

// Optional: If you still need to notify parent about totVal
}) => {
  // If onMessageScore is still needed to notify parent about totVal
 
  return (
    <Box sx={{ paddingLeft: '1em' }}>
      <Box sx={{ maxHeight: '40em', overflowY: 'auto', paddingLeft: '0.1em' }}>
        <Typography variant="h6" component="h3" gutterBottom>
          Message Content
        </Typography>

        {/* Receiver Score Section */}
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '0.6em',
            backgroundColor: 'whitesmoke',
            wordBreak: 'break-all',
          }}
          elevation={3}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="h6" component="h3">
              Receiver
            </Typography>
          </Box>
          <Box
            sx={{
              width: '90%',
              backgroundColor: 'lightgrey',
              textAlign: 'center',
              lineHeight: '30px',
              color: 'white',
              marginBottom: '1em',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: `${receiverScore}%`,
                height: '30px',
                backgroundColor: getBarColor(receiverScore),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'width 0.5s ease-in-out',
              }}
            >
              <Typography variant="body1" component="span" fontWeight="bold">
                {receiverScore}%
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1">
            The name <b>{displayName}</b> was mentioned <b>{nameCount}</b> times in this email.
          </Typography>
        </Paper>

        {/* Phishing Words Section */}
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '0.6em',
            backgroundColor: 'whitesmoke',
            wordBreak: 'break-all',
          }}
          elevation={3}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="h6" component="h3">
              Phishing Words
            </Typography>
          </Box>
          <Box
            sx={{
              width: '90%',
              backgroundColor: 'lightgrey',
              textAlign: 'center',
              lineHeight: '30px',
              color: 'white',
              marginBottom: '1em',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: `${dictScore}%`,
                height: '30px',
                backgroundColor: getBarColor(dictScore),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'width 0.5s ease-in-out',
              }}
            >
              <Typography variant="body1" component="span" fontWeight="bold">
                {dictScore}%
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1">
            Phishing words found: <b>{spamWordCount}</b>
          </Typography>
         </Paper>

        {/* Total Evaluation Section */}
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '0.6em',
            backgroundColor: 'whitesmoke',
            wordBreak: 'break-all',
          }}
          elevation={3}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="h6" component="h3">
              Evaluation
            </Typography>
          </Box>
          <Box
            sx={{
              width: '90%',
              backgroundColor: 'lightgrey',
              textAlign: 'center',
              lineHeight: '30px',
              color: 'white',
              marginBottom: '1em',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: `${totVal}%`,
                height: '30px',
                backgroundColor: getBarColor(totVal),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'width 0.5s ease-in-out',
              }}
            >
              <Typography variant="body1" component="span" fontWeight="bold">
                {totVal}%
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1">Total evaluation score</Typography>
        </Paper>
      </Box>

      <Button
        variant="contained"
        color="primary"
        sx={{ margin: 1, mt: 2 }}
        component={Link}
        to="/"
      >
        Go back
      </Button>
    </Box>
  );
};

Content.propTypes = {
  receiverScore: PropTypes.number.isRequired,
  dictScore: PropTypes.number.isRequired,
  spamWordCount: PropTypes.number.isRequired,
  totVal: PropTypes.number.isRequired,
  nameCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  displayName: PropTypes.string.isRequired,
};

export default Content;
