import * as React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CssBaseline,
  Box
} from '@mui/material';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const DashboardPage = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Email Evaluation Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
  variant="temporary" // for mobile devices
  open
  sx={{
    display: { xs: 'none', sm: 'block' }, // Hide on mobile
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
  }}
  ModalProps={{
    keepMounted: true, // Better open performance on mobile.
  }}
>
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/EmailSelectionPage">
                <ListItemText primary="Select Email" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/Sender">
                <ListItemText primary="Sender Info" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/Content">
                <ListItemText primary="Content Evaluation" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/Links">
                <ListItemText primary="Link Evaluation" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/Authentication">
                <ListItemText primary="Authentication" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/TotalEvaluation">
                <ListItemText primary="Total Evaluation" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default DashboardPage;
