import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Card, CardActions, CardContent, CardActionArea, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const CardInfoList = () => {
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  card: {
    maxWidth: '95%',
    marginTop: '.5em',
    backgroundColor: 'WhiteSmoke',
  },
  cards: {
    overflowY: 'scroll',
    maxHeight: '20em',
    paddingLeft: '.1em',
  },
}));
const classes = useStyles();


  return (
    <div className={classes.root}>
      <div className={classes.cards}>
        {/* Total Evaluation Card */}
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <Typography variant="h6">Total Evaluation</Typography>
              <Typography variant="body2">Email evaluation guidance.</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" component={Link} to="/totalevaluation">
                Learn More
              </Button>
            </CardActions>
          </CardActionArea>
        </Card>

        {/* Sender Information Card */}
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <Typography variant="h6">Sender Information</Typography>
              <Typography variant="body2">Name and email address credibility.</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" component={Link} to="/sender">
                Learn More
              </Button>
            </CardActions>
          </CardActionArea>
        </Card>

        {/* Message Content Card */}
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <Typography variant="h6">Message Content</Typography>
              <Typography variant="body2">Email content relevance/legitimacy.</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" component={Link} to="/content">
                Learn More
              </Button>
            </CardActions>
          </CardActionArea>
        </Card>

        {/* Links Card */}
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <Typography variant="h6">Links</Typography>
              <Typography variant="body2">Trustworthiness of links in the email.</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" component={Link} to="/links">
                Learn More
              </Button>
            </CardActions>
          </CardActionArea>
        </Card>

        {/* Authentication Card */}
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <Typography variant="h6">Authentication</Typography>
              <Typography variant="body2">Email security protocols.</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" component={Link} to="/authentication">
                Learn More
              </Button>
            </CardActions>
          </CardActionArea>
        </Card>

        {/* Attachments Card */}
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <Typography variant="h6">Attachments</Typography>
              <Typography variant="body2">Trustworthiness of attached files.</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" component={Link} to="/attachments">
                Learn More
              </Button>
            </CardActions>
          </CardActionArea>
        </Card>
      </div>
    </div>
  );
};

CardInfoList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default CardInfoList;
  
 



