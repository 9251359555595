import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import CardInfoList from './CardInfoList';
import MouseOverPopover from './MouseOverPopover';
import PropTypes from 'prop-types';

const MainList = ({
  sendScore,
  messageScore,
  linkScore,
  authScore
}) => {
  const [safetyType, setSafetyType] = useState('null');
  const [totVal, setTotVal] = useState(0);
  const calculateTotalEvaluation = () => {
    const total = Math.round((sendScore + messageScore + linkScore + authScore) / 4);
    setTotVal(total);
    updateProgressBar(total, 'tot_ev');
  };

  useEffect(() => {
    calculateTotalEvaluation();
  });

  const SafetyType = (totVal) => {
    if (totVal <= 25) setSafetyType('NO');
    else if (totVal > 25 && totVal <= 50) setSafetyType('LOW');
    else if (totVal > 50 && totVal <= 75) setSafetyType('MEDIUM');
    else setSafetyType('HIGH');
  };

  useEffect(() => {
    SafetyType(totVal);  // Pass `totVal`
  }, [totVal]);

  const updateProgressBar = (score, barId) => {
    const elem = document.getElementById(barId);
    if (elem) {
      elem.style.width = `${score}%`;
      elem.style.backgroundColor =
        score <= 25 ? 'red' :
        score <= 50 ? 'OrangeRed' :
        score <= 75 ? 'orange' :
        'green';
    }
  };

  return (
    <Box sx={{ padding: '1em' }}>
      <Typography variant="h6">Email Evaluation</Typography>

      {/* Sender Evaluation Section */}
      <Paper sx={{ padding: 2, marginTop: '0.6em', backgroundColor: 'WhiteSmoke' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Sender</Typography>
          <MouseOverPopover tekst="Shows evaluation of the sender's email address and name." />
        </Box>
        <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
          <Box id="send" sx={{ height: '30px', backgroundColor: 'green', width: `${sendScore}%` }}>
            <b>{sendScore}%</b>
          </Box>
        </Box>
      </Paper>

      {/* Content Evaluation Section */}
      <Paper sx={{ padding: 2, marginTop: '0.6em', backgroundColor: 'WhiteSmoke' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Content</Typography>
          <MouseOverPopover tekst="Shows evaluation of the email content's legitimacy." />
        </Box>
        <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
          <Box id="content" sx={{ height: '30px', backgroundColor: 'green', width: `${messageScore}%` }}>
            <b>{messageScore}%</b>
          </Box>
        </Box>
      </Paper>

      {/* Links Evaluation Section */}
      <Paper sx={{ padding: 2, marginTop: '0.6em', backgroundColor: 'WhiteSmoke' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Links</Typography>
          <MouseOverPopover tekst="Shows evaluation of the links found in the email." />
        </Box>
        <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
          <Box id="links" sx={{ height: '30px', backgroundColor: 'green', width: `${linkScore}%` }}>
            <b>{linkScore}%</b>
          </Box>
        </Box>
      </Paper>

      {/* Authentication Evaluation Section */}
      <Paper sx={{ padding: 2, marginTop: '0.6em', backgroundColor: 'WhiteSmoke' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Authentication</Typography>
          <MouseOverPopover tekst="Shows evaluation of the email's authentication mechanisms (SPF, DKIM, DMARC)." />
        </Box>
        <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
          <Box id="auth" sx={{ height: '30px', backgroundColor: 'green', width: `${authScore}%` }}>
            <b>{authScore}%</b>
          </Box>
        </Box>
      </Paper>

      {/* Total Evaluation Section */}
      <Paper sx={{ padding: 2, marginTop: '0.6em', backgroundColor: 'WhiteSmoke' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Total Evaluation</Typography>
          <MouseOverPopover tekst="Total evaluation of the email based on the key attributes." />
        </Box>
        <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
          <Box id="tot_ev" sx={{ height: '30px', backgroundColor: 'green', width: `${totVal}%` }}>
            <b>{totVal}%</b>
          </Box>
        </Box>
      </Paper>

      <Box sx={{ marginTop: '1em', textAlign: 'center' }}>
        <Typography variant="h6">{safetyType} EMAIL SAFETY</Typography>
      </Box>

      <CardInfoList />
    </Box>
  );
};

MainList.propTypes = {
  sendScore: PropTypes.number.isRequired,
  messageScore: PropTypes.number.isRequired,
  linkScore: PropTypes.number.isRequired,
  authScore: PropTypes.number.isRequired,
};

export default MainList;
