import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import MouseOverPopover from './MouseOverPopover';
import Box from '@mui/material/Box';

export default class TotalEvaluation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      high: 76,
      medium: 51,
      low: 26,
      no: 0,
    };
  }

  componentDidMount() {
    this.getValues();
  }

  // Get percent score interval values
  getValues = async () => {
    this.progBar(this.state.high, 'bar_1');
    this.progBar(this.state.medium, 'bar_2');
    this.progBar(this.state.low, 'bar_3');
    this.progBar(this.state.no, 'bar_4');
  };

  // Update progress bar based on score
  progBar = async (c, text) => {
    const elem = document.getElementById(text);
    if (elem) {
      elem.style.width = '100%';
      elem.style.backgroundColor =
        c <= 25
          ? 'red'
          : c <= 50
          ? 'OrangeRed'
          : c <= 75
          ? 'orange'
          : 'green';
    }
  };

  render() {
    return (
      <Box sx={{ paddingLeft: '1em' }}>
        <Box sx={{ maxHeight: '40em', overflowY: 'auto', paddingLeft: '.1em' }}>
          <Typography variant="h6" component="h3">
            Total Evaluation
          </Typography>
          {/* High safety */}
          <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="h3">
                High safety
              </Typography>
              <MouseOverPopover tekst="High safety does not mean the email is 100% safe. There can be false positives or negatives. This rating serves as guidance for cautious email interactions." />
            </Box>
            <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
              <Box id="bar_1" sx={{ height: '30px', backgroundColor: 'green', width: `${this.state.high}%` }}>
                <b>{this.state.high}%</b>
              </Box>
            </Box>
            <Typography>
              A rating between 76-100 is considered a legitimate email with high safety. It is less likely to be a phishing email.
            </Typography>
          </Paper>

          {/* Medium safety */}
          <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="h3">
                Medium safety
              </Typography>
              <MouseOverPopover tekst="Medium safety means some email attributes did not meet expected criteria. Review the email carefully." />
            </Box>
            <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
              <Box id="bar_2" sx={{ height: '30px', backgroundColor: 'orange', width: `${this.state.medium}%` }}>
                <b>{this.state.medium}%</b>
              </Box>
            </Box>
            <Typography>
              A rating between 51-75 indicates medium safety. The email is unlikely to be phishing, but requires caution.
            </Typography>
          </Paper>

          {/* Low safety */}
          <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="h3">
                Low safety
              </Typography>
              <MouseOverPopover tekst="Low safety indicates several email attributes failed to meet criteria. Examine the email thoroughly." />
            </Box>
            <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
              <Box id="bar_3" sx={{ height: '30px', backgroundColor: 'OrangeRed', width: `${this.state.low}%` }}>
                <b>{this.state.low}%</b>
              </Box>
            </Box>
            <Typography>
              A rating between 26-50 indicates low safety. This email is likely to be phishing, proceed with caution.
            </Typography>
          </Paper>

          {/* No safety */}
          <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="h3">
                No safety
              </Typography>
              <MouseOverPopover tekst="No safety indicates the majority of attributes failed to meet the expected criteria. It is most likely a phishing email." />
            </Box>
            <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
              <Box id="bar_4" sx={{ height: '30px', backgroundColor: 'red', width: `${this.state.no}%` }}>
                <b>{this.state.no}%</b>
              </Box>
            </Box>
            <Typography>
              A rating between 0-25 is considered no safety. It is highly recommended to avoid interacting with this email.
            </Typography>
          </Paper>
        </Box>

        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }} component={Link} to="/">
          Go back
        </Button>
      </Box>
    );
  }
}

TotalEvaluation.propTypes = {
  onScoreChange: PropTypes.func,
};