import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import MouseOverPopover from './MouseOverPopover';


// Authentication component updated to use props and MUI v5
const Authentication = ({
  dmarcScore,
  dkimScore,
  spfScore,
  totVal,
  spfText,
  dkimText,
  dmarcText,
}) => {

  
  // Giving color to the progress bar based on the score
  const getProgressBarStyle = (totVal) => {
    if (totVal <= 25) return { backgroundColor: 'red' };
    if (totVal > 25 && totVal <= 50) return { backgroundColor: 'OrangeRed' };
    if (totVal > 50 && totVal <= 75) return { backgroundColor: 'orange' };
    return { backgroundColor: 'green' };
  };

  return (
    <div style={{ paddingLeft: '1em' }}>
      <div style={{ maxHeight: '40em', overflowY: 'auto', paddingLeft: '.1em' }}>
        <Typography variant="h6" component="h3">
          Authentication
        </Typography>
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="h3">
              SPF
            </Typography>
            <MouseOverPopover
              tekst="Sender Policy Framework (SPF) allows the receiving mail server to check during mail delivery that a mail claiming to come from a specific domain is submitted by an IP address authorized by that domain's administrators."
            />
          </div>
          <div style={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white' }}>
            <div style={{ ...getProgressBarStyle(spfScore), width: '100%', height: '30px' }}>
              <p><b>{spfScore}%</b></p>
            </div>
          </div>
          <p>SPF: <b>{spfText}</b></p>
          
        </Paper>

        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="h3">
              DKIM
            </Typography>
            <MouseOverPopover
              tekst="Domain Keys Identified Mail (DKIM) is an email authentication technique that allows the receiver to check that an email was indeed sent and authorized by the owner of that domain by giving the email a digital signature."
            />
          </div>
          <div style={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white' }}>
            <div style={{ ...getProgressBarStyle(dkimScore), width: '100%', height: '30px' }}>
              <p><b>{dkimScore}%</b></p>
            </div>
          </div>
          <p>DKIM: <b>{dkimText}</b></p>
        </Paper>

        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="h3">
              DMARC
            </Typography>
            <MouseOverPopover
              tekst="Domain-based Message Authentication, Reporting, and Conformance (DMARC) is a protocol that uses SPF and DKIM to determine the authenticity of an email message."
            />
          </div>
          <div style={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white' }}>
            <div style={{ ...getProgressBarStyle(dmarcScore), width: '100%', height: '30px' }}>
              <p><b>{dmarcScore}%</b></p>
            </div>
          </div>
          <p>DMARC: <b>{dmarcText}</b></p>
        </Paper>

        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="h3">
              Evaluation
            </Typography>
            <MouseOverPopover
              tekst="A total evaluation of the attributes found in email authentication. The score is an average of SPF, DKIM, and DMARC."
            />
          </div>
          <div style={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white' }}>
            <div style={{ ...getProgressBarStyle(totVal), width: '100%', height: '30px' }}>
              <p><b>{totVal}%</b></p>
            </div>
          </div>
          <p>The percentage score should almost always be 100 as it is required to pass verification of SPF, DKIM, and DMARC to rate the email as safe.</p>
        </Paper>
      </div>

      <Button variant="contained" color="primary" component={Link} to="/" sx={{ marginTop: 1 }}>
        Go back
      </Button>
    </div>
  );
};

Authentication.propTypes = {
  dmarcScore: PropTypes.number.isRequired,
  dkimScore: PropTypes.number.isRequired,
  spfScore: PropTypes.number.isRequired,
  totVal: PropTypes.number.isRequired,
  spfText: PropTypes.string.isRequired,
  dkimText: PropTypes.string.isRequired,
  dmarcText: PropTypes.string.isRequired,
  onAuthScore: PropTypes.func.isRequired,
};

export default Authentication;
