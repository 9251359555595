import * as React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import MouseOverPopover from './MouseOverPopover';

const Links = ({
  emailAdr,
  displayName,
  numLinks,
  numRedirect,
  numEncoding,
  linksHTTPS,
  linkArray,
  linkRedirectScore,
  linkHttpsScore,
  linkEncodeScore,
  linkDuplicateScore,
  linkScore,
  duplicateLinks,
  linksTotVal,
}) => {

  // Color for progress bar based on score
  const progBarColor = (score) => {
    if (score <= 25) return 'red';
    if (score > 25 && score <= 50) return 'OrangeRed';
    if (score > 50 && score <= 75) return 'orange';
    return 'green';
  };

  return (
    <Box sx={{ paddingLeft: '1em' }}>
      <Box sx={{ maxHeight: '40em', overflowY: 'auto', paddingLeft: '.1em' }}>
        <Typography variant="h6" component="h3">
          Links in email
        </Typography>

        {/* Link-Sender relation */}
        <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Link-Sender relation</Typography>
            <MouseOverPopover tekst="Legitimate websites usually have their company name in their display name, email domain and links." />
          </Box>
          <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
            <Box sx={{ height: '30px', backgroundColor: progBarColor(linkScore), width: `${linkScore}%` }}>
              <b>{linkScore}%</b>
            </Box>
          </Box>
          <Typography>Display Name: <b>{displayName}</b></Typography>
          <Typography>Email Address: <b>{emailAdr}</b></Typography>
          <Typography>Distinct Link hostname(s):</Typography>
          <Box sx={{ maxHeight: '5.3em', overflowY: 'auto' }}>
            {linkArray.map((item, index) => (
              <ul key={index}><b>{item}</b></ul>
            ))}
          </Box>
        </Paper>

        {/* URL Encoding */}
        <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">URL Encoding</Typography>
            <MouseOverPopover tekst="URL encoding replaces unsafe ASCII characters with a % followed by two hexadecimal digits." />
          </Box>
          <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
            <Box sx={{ height: '30px', backgroundColor: progBarColor(linkEncodeScore), width: `${linkEncodeScore}%` }}>
              <b>{linkEncodeScore}%</b>
            </Box>
          </Box>
          <Typography>{numEncoding} of {numLinks} link(s) contains URL encoding</Typography>
        </Paper>

        {/* Link Security */}
        <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Link Security</Typography>
            <MouseOverPopover tekst="Hypertext Transfer Protocol Secure (HTTPS) is an extension of HTTP used for secure communication." />
          </Box>
          <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
            <Box sx={{ height: '30px', backgroundColor: progBarColor(linkHttpsScore), width: `${linkHttpsScore}%` }}>
              <b>{linkHttpsScore}%</b>
            </Box>
          </Box>
          <Typography>{linksHTTPS} of {numLinks} link(s) have HTTPS (SSL)</Typography>
        </Paper>

        {/* Redirecting Links */}
        <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Redirecting Links</Typography>
            <MouseOverPopover tekst="Some links contain redirection. This means the link redirects to somewhere else." />
          </Box>
          <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
            <Box sx={{ height: '30px', backgroundColor: progBarColor(linkRedirectScore), width: `${linkRedirectScore}%` }}>
              <b>{linkRedirectScore}%</b>
            </Box>
          </Box>
          <Typography>{numRedirect} of {numLinks} link(s) contains redirection</Typography>
        </Paper>

        {/* Duplication of Links */}
        <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Duplication of Links</Typography>
            <MouseOverPopover tekst="Most phishing emails contain duplications of URL links." />
          </Box>
          <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
            <Box sx={{ height: '30px', backgroundColor: progBarColor(linkDuplicateScore), width: `${linkDuplicateScore}%` }}>
              <b>{linkDuplicateScore}%</b>
            </Box>
          </Box>
          <Typography>{duplicateLinks} of {numLinks} link(s) are the same</Typography>
        </Paper>

        {/* Total Evaluation */}
        <Paper sx={{ padding: 2, maxWidth: '85%', marginTop: '.6em', backgroundColor: 'WhiteSmoke', wordBreak: 'break-all' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Evaluation</Typography>
            <MouseOverPopover tekst="A total evaluation of the links found in the email." />
          </Box>
          <Box sx={{ width: '90%', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '30px', color: 'white', marginBottom: '1em' }}>
            <Box sx={{ height: '30px', backgroundColor: progBarColor(linksTotVal), width: `${linksTotVal}%` }}>
              <b>{linksTotVal}%</b>
            </Box>
          </Box>
          <Typography>Total evaluation of link(s) in this email</Typography>
        </Paper>
      </Box>
      <Button variant="contained" color="primary" sx={{ marginTop: '1em' }} component={Link} to="/">
        Go back
      </Button>
    </Box>
  );
};

Links.propTypes = {
  emailAdr: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  numLinks: PropTypes.number.isRequired,
  numRedirect: PropTypes.number.isRequired,
  numEncoding: PropTypes.number.isRequired,
  linksHTTPS: PropTypes.number.isRequired,
  linkArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  linkRedirectScore: PropTypes.number.isRequired,
  linkHttpsScore: PropTypes.number.isRequired,
  linkEncodeScore: PropTypes.number.isRequired,
  linkDuplicateScore: PropTypes.number.isRequired,
  linkScore: PropTypes.number.isRequired,
  duplicateLinks: PropTypes.number.isRequired,
  linksTotVal: PropTypes.number.isRequired,
};

export default Links;
