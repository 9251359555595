import React from 'react';
import PropTypes from 'prop-types';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';

const EmailSelectionPage = ({ listItems, onEmailSelect }) => {
  return (
    <Box sx={{ padding: '1em' }}>
      <Typography variant="h5" gutterBottom>
        Select an Email
      </Typography>
      <List>
        {listItems.length > 0 ? (
          listItems.map((item) => (
            <ListItem 
              key={item.id} 
              component={ButtonBase}  // Use ButtonBase to make it clickable
              onClick={() => onEmailSelect(item.id)}
              sx={{ 
                '&:hover': { backgroundColor: '#f0f0f0' },
                width: '100%',
                textAlign: 'left',
                padding: { xs: '10px', sm: '15px' },   // Align text left for better button behavior
              }}
            >
              <ListItemText 
              primary={item.senderEmailDisplayName} 
              secondary={item.senderEmailEmailAddress} 
            />
          </ListItem>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            No emails available
          </Typography>
        )}
      </List>
    </Box>
  );
};

// Prop validation to ensure correct types
EmailSelectionPage.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      senderEmailDisplayName: PropTypes.string.isRequired,
      senderEmailEmailAddress: PropTypes.string.isRequired,
    })
  ).isRequired,
  onEmailSelect: PropTypes.func.isRequired,
};

export default EmailSelectionPage;
